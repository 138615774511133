<template>
    <div>
        <div class="row mt-5">
            <div class="col-md-10 offset-md-1">
                <div class="card card-custom gutter-b">
                    <div class="card-header">
                        <div class="card-title">
                            <h3 class="card-label">Create Container</h3>
                        </div>
                    </div>
                    <div class="card-body body-fluid">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="font-weight-bold">Airwaybill no:<span class="text-danger">*</span></label>
                                <b-form-input placeholder="AWB12345" v-model="form.airwaybill"></b-form-input>
                            </div>
                            <div class="col-md-6">
                                <label class="font-weight-bold">Type <span class="text-danger">*</span></label>
                                <b-form-select :options="containerType" value-field="id" v-model="form.typeid"
                                    text-field="name" size="md" class="">
                                    <template #first>
                                        <b-form-select-option :value="null" disabled>Select Type</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label class="font-weight-bold">Origin <span class="text-danger">*</span></label>
                                <b-form-input placeholder="Miami" v-model="form.origin"></b-form-input>
                            </div>
                            <div class="col-md-4">
                                <label class="font-weight-bold">
                                    <i class="flaticon1-arrow-down"></i></label>
                            </div>
                            <div class="col-md-4">
                                <label class="font-weight-bold">Destination</label>
                                <b-form-input placeholder="Jamaica" v-model="form.destination"></b-form-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <label class="font-weight-bold">Departure Date <span class="text-danger">*</span></label>
                                <b-form-input type="date" v-model="form.departuredate"></b-form-input>
                            </div>
                            <div class="col-md-3">
                                <label class="font-weight-bold">Arrival Date <span class="text-danger">*</span></label>
                                <b-form-input type="date" v-model="form.arrivaldate"></b-form-input>
                            </div>
                            <div class="col-md-3">
                                <label class="font-weight-bold">Authorized name <span class="text-danger">*</span></label>
                                <b-form-input type="text" v-model="form.authorizedname"></b-form-input>
                            </div>
                            <div class="col-md-3">
                                <label class="font-weight-bold">Flight/Ship/Truck No.<span
                                        class="text-danger">*</span></label>
                                <b-form-input type="text" placeholder="FL-2125565" v-model="form.flightno"></b-form-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <b-form-group label="Select Consigne">
                                    <b-form-select v-model="form.consigne_id" :options="consignes" value-field="id"
                                        text-field="name"></b-form-select>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="font-weight-bold">Additional Information
                                    <span class="text-danger">*</span></label>
                                <b-form-textarea id="container-aditional-info" maxlength="256"
                                    placeholder="Type your message" v-model="form.additionalinformation"></b-form-textarea>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button class="btn btn-primary float-right" @click="submit()">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <loading :active.sync="isLoading"></loading>
    </div>
</template>

<style>
.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>th {
    padding: 10px;
}

#productTable .form-control {
    min-width: 100px;
}

.vweight {
    width: 141px;
}

.modal-open {
    overflow: initial;
}
</style>

<script>
// import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import {
    SET_BREADCRUMB
} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
// import singaporePostalCode from "@/assets/data/singaporePostalCode.json";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
    data() {
        return {
            consignes: [],
            form: {
                airwaybill: "",
                origin: "",
                destination: "",
                departuredate: new Date(),
                arrivaldate: new Date(),
                authorizedname: "",
                flightno: "",
                additionalinformation: "",
                typeid: 1,
                consigne_id: null,
                is_active: true,
            },

            containerType: [{
                id: 1,
                name: "Air"
            },
            {
                id: 2,
                name: "Ship"
            },
            {
                id: 3,
                name: "Truck"
            },
            ],
        };
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{
            title: "Container"
        },
        {
            title: "Add"
        },
        ]);
        this.getActiveConsigne();
    },
    components: {
        Loading,
    },
    methods: {
        getActiveConsigne() {
            ApiService.get("consignee")
                .then(({
                    data
                }) => {
                    debugger;
                    this.consignes = data;

                    this.isLoading = false;
                })
                .catch(() => { });
        },
        checkErrors() {
            if (
                this.form.airwaybill &&
                this.form.origin &&
                this.form.destination &&
                this.form.typeid
            ) {
                return true;
            }
            this.errors = [];
            if (!this.form.airwaybill) {
                this.errors.push("Airwaybill is required.");
            }
            if (!this.form.origin) {
                this.errors.push("Origin is required.");
            }
            if (!this.form.destination) {
                this.errors.push("Destination is required.");
            }
            if (!this.form.typeid) {
                this.errors.push("Type is required.");
            }
            for (let field of this.errors) {
                this.makeToastVariant("danger", field, "Error");
            }
        },
        submit() {
            if (this.checkErrors()) {
                this.isLoading = true;
                ApiService.post("container", this.form)
                    .then(({ data }) => {
                        this.$nextTick(() => {
                            this.makeToastVariant(
                                "success",
                                "Container added successfully",
                                "Success"
                            );

                            this.$router.push({
                                name: "viewcontainer",
                                params: {
                                    id: data.id
                                }
                            });
                        });
                    })
                    .catch(({
                        response
                    }) => {
                        this.isLoading = false;
                        for (let field of Object.keys(response.data.error)) {
                            this.makeToastVariant(
                                "danger",
                                response.data.error[field][0],
                                "Error"
                            );
                        }
                    });
            }
        },
        makeToastVariant(variant = null, text, title) {
            this.$bvToast.toast(text, {
                title: title,
                variant: variant,
                solid: true,
            });
        },
       
    },

};
</script>
